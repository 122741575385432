import { RouterModule, Routes } from '@angular/router';

import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {path: '', component: ComingSoonComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}