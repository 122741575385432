<section class="coming-area">
    <div class="coming-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-text">
                        <img style="height: auto; width: 200px;" src="assets/img/logo.png" alt="">
                        <h1>En construcción...</h1>
                        <p>¡Bienvenidos a la página web de la <strong>Federación Iberoamericana de los Derechos Humanos</strong>!
                            <br>
                            Estamos trabajando para ofrecerles un sitio más moderno, accesible e informativo, donde podrán conocer más sobre nuestra historia, nuestros miembros, nuestras redes temáticas, nuestras acciones y nuestros proyectos.
                            <br>
                            Gracias por su paciencia y su interés. <strong>¡Nos vemos pronto!</strong> 
                        </p>
                        <div class="row coming-wrap">
                            <div class="col-6 col-sm-6 col-lg-3">
                                <div class="coming-inner">
                                    <h3 id="days"></h3>
                                    <p>Días</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-3">
                                <div class="coming-inner">
                                    <h3 id="hours"></h3>
                                    <p>Horas</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-3">
                                <div class="coming-inner">
                                    <h3 id="minutes"></h3>
                                    <p>Minutos</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-3">
                                <div class="coming-inner">
                                    <h3 id="seconds"></h3>
                                    <p>Segundos</p>
                                </div>
                            </div>
                        </div>

                        <ul>
                            <!-- <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-pinterest'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>